export default {
  tips: {
    errCode: {
      400: "参数错误",
      403: "权限受限，请联系管理员",
      404: "请求链接不存在,请联系管理员",
      405: "请求方式不正确,应为POST/GET",
      500: "服务器错误,请联系管理员",
      default: "无效的请求,状态码($),请联系管理员",
      other: "未知错误，请联系管理排查"
    }
  },
  projectId: 2,
  companyId: 2,
  // projectId: [26, 28, 29],
  // branch:"dev",
  prefix: "",
  localhost: '/kapi',

  host: "https://pbx.kclass.imedunion.com",
  logo: "https://kclass.imedunion.com/image/logo.png",
  websocketHost: "wss://pbx.kclass.imedunion.com/pbx_ws",
  theme: {
    "9e004b": {
      color: "#9e004b",
      name: ""
    },
    "80b25f": {
      color: "#80b25f",
      name: 'mutai'
    },
    "1c355f": {
      color: "#1c355f",
      name: 'aishijian'
    },
    "004890": {
      color: "#004890",
      name: 'pubeixi'
    },
  },
  statement: "本材料目的在于传递医药前沿信息、研究进展和科学信息交流，非广告用途，亦不构成对任何药物的商业推广或对诊疗方案的推荐。本材料仅供医疗卫生专业人士参考，内容可能含有未在中国批准的临床适应症，处方请参考国家药品监督管理局批准的药品说明书。本材料仅代表讲者个人观点，讲者对材料内容的真实性和准确性独立负责",
  resume: {
    text: 23,
    row: 7
  },
  categary: {
    role: {
      doctor: 1, // 专家
      guest: 0 // 观众
    },
    template: {
      image: 0,
      text: 1
    },
    phase: {
      meeting_start: 0,
      meeting_course: 1,
      meeting_discuss: 2,
      meeting_end: 3
    },
    audit: {
      init: 0,
      auditing: 10,
      submitAudit: 15,
      success: 20,
      rerecord: 25,
      fail: 30
    },
    meeting: {
      0: "待录制",
      1: "进行中",
      2: "管理员审核中",
      3: "审核通过",
      4: "审核未通过",
      5: "审核通过",  // "待支付",
      6: "已支付",
      7: "支付失败",
      8: "系统审核中",
      9: "待重新录制",
      10: "请选题"
    },
    week: ["日", "一", "二", "三", "四", "五", "六"],
    files: {
      card: "card",
      agreement: "agreement",
      courseware: "courseware",
    }
  },
  mainColorConfig: {
    37: {
      fontColor: "#004890",
      bgColor: "#edf6fe"
    },
    38: {
      fontColor: "#004890",
      bgColor: "#edf6fe"
    },
    1: {
      fontColor: "#004890",
      bgColor: "#edf6fe"
    },
  },
  buttonGroup: {
    0: { title: "选择该文献", background: "#0047e9" },
    1: { title: "已选择该文献", background: "#b7d635" },
    2: { title: "该文献已选完", background: "#c6c8c7" },
  },
  newButtonGroup: {
    0: {
      "#0047e9": { title: "选择该文献", background: "#0047e9" },
      "#004890": { title: "选择该文献", background: "#004890" },
    },
    1: {
      "#0047e9": { title: "已选择该文献", background: "#b7d635" },
      "#004890": { title: "已选择该文献", background: "#c34c41" },
    },
    2: {
      "#0047e9": { title: "该文献已选完", background: "#c6c8c7" },
      "#004890": { title: "该文献已选完", background: "#c6c8c7" },
    }
  },
  wxinterface: [
    "startRecord",
    "stopRecord",
    "onVoiceRecordEnd",
    "playVoice",
    "pauseVoice",
    "stopVoice",
    "onVoicePlayEnd",
    "uploadVoice",
    "downloadVoice",
    "closeWindow",
    "chooseImage",
    "uploadImage",
    "scanQRCode",
    "updateAppMessageShareData",
    "onMenuShareAppMessage",
    "onMenuShareTimeline",
    "updateTimelineShareData",
  ]

}
